import React from "react";
import SEO from "../../components/SEO";
import { Heading, Section } from "react-bulma-components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const description =
  "Är du redo att ta ditt golfande till nästa nivå? Med dessa bollar anordnar du enkelt mörkergolf för hela sällskapet. En rolig twist på den vanliga golfrundan.";

const BlogPost = () => {
  return (
    <>
      <SEO title="Självlysande Golfbollar | Wallang" description={description} />
      <Section className="blog">
        <Heading className="has-text-centered">Självlysande golfbollar</Heading>
        <p className="pt-4">
          Är du redo att ta ditt golfande till nästa nivå? Vi som driver Wallang är stora golfentusiaster. Vi spelar ofta och har alltid tyckt att det blivit utmanande när det
          börjar skymma. Så fort vi provade självlysande golfbollar tyckte vi att det var en häftig upplevelse. Vi kan verkligen rekommendera att testa. Det blir garanterat en
          rolig upplevelse för hela sällskapet. Reaktioner efter att ha upplevt de självlysande bollarna för första gången:
        </p>

        <p className="blog__quote">"Wow! Man ser verkligen bollen på ett helt annat sätt. Det var mycket häftigare än jag trodde. Vill genast börja anordna mörkergolf."</p>

        <p className="blog__quote">
          "Första gången jag kom i kontakt med självlysande bollar var när vi precis skyndat in i skymningen. Samtidigt slog en tjej ut på första tee och hennes drive flög iväg som
          ett lysande sken mot greenen, så häftigt!"
        </p>
        <p>
          Bilderna nedan är från den gången vi testade bollarna. Det är våra egna bilder och vi vill gärna att även du ska få möjligheten att testa. Vi säljer bollarna i 3-pack i
          slumpade färger. Färgen aktiveras när du slår till bollen. Det blir en häftig effekt när färgen aktiveras och bollen flyger iväg. Bollen består av LED-lampor och har en
          lystid på ca 8 minuter innan den behöver aktiveras på nytt. En boll väger ca 45.3 gram vilket är i linje med de rekommendationer som finns för en golfboll.
        </p>
        <p className="pt-3">
          Gå till <Link to="/prylar/sjalvlysande-golfbollar-3-pack">produktsidan</Link> för att läsa mer och lägga en order.
        </p>
        <div className="blog__images">
          <StaticImage placeholder="blurred" width={300} height={300} src="../../images/golfboll4.jpg" alt="Golfboll produktbild 1" />
          <StaticImage placeholder="blurred" width={300} height={300} src="../../images/golfboll3.jpg" alt="Golfboll produktbild 2" />
          <StaticImage placeholder="blurred" width={300} height={300} src="../../images/golfboll2.jpg" alt="Golfboll produktbild 3" />
          <StaticImage placeholder="blurred" width={300} height={300} src="../../images/golfboll1.jpg" alt="Golfboll produktbild 4" />
          <StaticImage placeholder="blurred" width={300} height={300} src="../../images/golfboll5.jpg" alt="Golfboll produktbild 5" />
          <StaticImage placeholder="blurred" width={300} height={300} src="../../images/golfboll6.jpg" alt="Golfboll produktbild 6" />
        </div>

        <p className="pt-3">
          Gå till <Link to="/prylar/sjalvlysande-golfbollar-3-pack">produktsidan</Link> för att läsa mer och lägga en order.
        </p>
      </Section>
    </>
  );
};

export default BlogPost;
